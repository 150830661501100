.jalali-calendar {
    .jc-header {
        display: flex;
        margin: 0 9px;
    }
    .jc-header-month {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .jc-header-prev,
    .jc-header-today,
    .jc-header-next {
        padding: 10px;
        background: transparent;
        border: 0;
        box-shadow: 0 0 1px #000;
        border-radius: 5px;
        margin: 5px;
    }

    .jc-content-header {
        display: flex;
        flex-wrap: nowrap;
        margin-top: 10px;
        background: #6176d4;
        color: white;
        border-radius: 5px;
        margin: 10px 9px 8px;
        .jc-content-header-item {
            flex: 0 0 14.185%;
            text-align: center;
            padding: 10px;
        }
    }
    .jc-content-body {
        display: flex;
        flex-wrap: wrap;

        .jc-day {
            flex: 0 0 14.285%;
            padding: 8px;
            overflow: hidden;
            position: relative;
        }

        .jc-day-events {
            display: none;
        }
    }

    .jc-day {
        .jc-day-link {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 1;
            cursor: pointer;
        }
        .jc-day-box {
            box-shadow: 0 0 1px rgb(156, 156, 156);
            height: 140px;
            padding: 10px;
            border-radius: 5px;
            background: #fff;
        }

        &.not-active {
            .jc-day-box {
                opacity: 0.5;
            }
        }

        &.holiday {
            .jc-day-box {
                background-color: rgba(#6176d4, 0.15);
            }
        }
    }

    .jc-content-body .jc-day:nth-child(7n) {
        .jc-day-box {
            background-color: rgba(#6176d4, 0.15);
        }
    }

    .jc-day-box-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .jc-day-single-event {
            font-size: 9px;
            padding-right: 5px;
        }
    }

    .jc-content-item {
        display: flex;
        align-items: center;
        border-radius: 5px;
        background-color: #e0e4f6;
        padding: 4px;
        overflow: hidden;
        max-width: 100%;
        .jc-content-item-image {
            padding-left: 6px;
            img {
                max-width: 18px;
            }
        }

        .jc-content-item-title {
            font-size: 13px;
            overflow: hidden;
            white-space: nowrap;
            max-width: 100%;
        }
    }
}
