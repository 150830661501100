$d-border-color: #bbb;
$d-radius: 8px;
$d-color: #d8263e;
$d-hover-color: #dc472e;
$d-color-light: #ffe8eb;
$green: #00c192;
$red: #b13741;
$joqqd-purple: #7c4192;
$default-color: #7c4192;
$btn-color: $joqqd-purple;
$old-blue: #1d3dcc;

.link-color {
    color: $joqqd-purple;
}

.default-color {
    color: $default-color;
}

.border-default-color {
    border-color: $default-color;
}

code {
    color: #e01a76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.dir-rtl {
    direction: rtl;
}

body {
    min-height: 100vh;
    margin: 0;
    overflow-x: hidden;
    background-color: #f8f9ff;
    direction: rtl;
}

.p-0 {
    padding: 0 !important;
}

.container {
    margin: 0 auto;
    display: flex;

    .sidebar {
        width: 60px;
        min-height: 800px;
        background-image: linear-gradient(#4c8deb, #4c60eb);
        display: flex;
        flex-direction: column;
        align-items: center;

        .circle {
            margin: 15px;
            width: 44px;
            height: 44px;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.5);
        }

        .img {
            padding: 17px 0;
            width: 100%;
            position: relative;

            .popup-sidebar {
                position: absolute;
                right: 100%;
                top: 50%;
                padding: 14px 0;
                background: #4c85eb;
                transform: translateY(-50%);
                color: white;
                max-width: 0;
                transition: 0.15s all linear;
                overflow: hidden;
                border-radius: 2px 0 0 2px;
            }

            &:hover {
                .popup-sidebar {
                    max-width: 300px;

                    padding: 14px;
                }
            }

            img {
                width: 19px;
                height: 17px;
                margin: auto;
            }
        }
    }

    .main-section {
        background-color: #f8f9ff;
        flex-grow: 1;

        .main-header {
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 16px;
            background-color: #fff;
            border-bottom: 1px solid #c0c8ff7c;

            form {
                border: 1px solid #b6d1f8;
                padding: 8px;
                border-radius: 5px;
                display: flex;
                align-items: center;

                input {
                    margin-right: 8px;
                    border: none;
                    outline: none;

                    &::placeholder {
                        color: #b6d1f8;
                    }
                }
            }

            .main-header-img {
                display: flex;
                align-items: center;

                img {
                    margin: 0 8px;
                }
            }
        }

        .customer-section {
            padding: 15px 16px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            h1 {
                font-weight: 400;
                font-size: 1.8rem;
            }

            button {
                background-image: linear-gradient(to right, #4c8deb, #4c60eb);
                background: $btn-color;
                border: none;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 40px;
                width: 115px;
                cursor: pointer;
                outline: none;
                border-radius: 4px;

                img {
                    margin-left: 8px;
                }
            }
        }

        .sort-section {
            background-color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 16px;
            padding: 0 16px;
            border-radius: 2px;
            box-shadow: 0 2px 10px #e1edff;

            .left {
                display: flex;
                align-items: center;

                div {
                    &:nth-of-type(1) {
                        margin-right: 48px;

                        img {
                            &:nth-of-type(1) {
                                margin-right: 0.25em;
                            }

                            &:nth-of-type(2) {
                                margin-left: 0.25em;
                            }
                        }
                    }

                    &:nth-of-type(2) {
                        display: flex;
                        align-items: center;

                        img {
                            margin-right: 8px;
                        }
                    }
                }
            }

            .right {
                display: flex;
                align-items: center;

                img {
                    &:nth-of-type(1) {
                        margin-right: 0.25em;
                    }
                }
            }
        }

        .box-section {
            padding: 0 16px;
            margin-top: 24px;
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 40px;

            .box {
                margin-bottom: 15px;
                border-top: 3px solid rgb(84, 166, 243);

                &.pink {
                    border-top: 3px solid rgb(207, 35, 207);
                }

                &.green {
                    border-top: 3px solid rgb(64, 247, 73);
                }

                &.blue {
                    border-top: 3px solid rgb(84, 166, 243);
                }

                &.green-2 {
                    border-top: 3px solid rgb(194, 238, 73);
                }

                &.red {
                    border-top: 3px solid rgb(255, 75, 75);
                }

                &.blue-2 {
                    border-top: 3px solid rgb(84, 255, 241);
                }

                &.blue-3 {
                    border-top: 3px solid rgb(38, 35, 207);
                }

                &.yellow {
                    border-top: 3px solid rgb(221, 217, 0);
                }

                &.orange {
                    border-top: 3px solid rgb(218, 131, 32);
                }

                border-radius: 6px;
                background-color: #fff;
                display: flex;
                flex-direction: column;
                padding: 8px 16px 16px;
                box-shadow: 0 2px 10px #e1edff;

                .box-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 8px;
                    padding-top: 8px;

                    h1 {
                        margin-top: 0;
                    }
                }

                .box-main {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-color: #edf4fd;
                    border-radius: 2px;
                    padding: 15px;
                    margin-top: 15px;

                    .left {
                        margin-right: 16px;

                        h4 {
                            margin-top: 0;
                            margin-bottom: 8px;
                            font-weight: 400;
                        }

                        h1 {
                            margin-top: 0;
                            font-size: 19px;
                        }
                    }

                    .right {
                        h4 {
                            margin-top: 0;
                            margin-bottom: 8px;
                            font-weight: 400;
                        }

                        h1 {
                            margin-top: 0;
                            font-size: 19px;
                        }
                    }
                }
            }
        }
    }
}

.container .sidebar .circle {
    margin: 15px;
    width: 44px;
    height: 44px;
    padding-top: 3px;
    padding-right: 1px;
    color: #38405b;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    border-bottom: 3px solid;
    border-top: 3px solid;
}

.button {
    background-image: linear-gradient(to right, #4c8deb, #4c60eb);
    background: $btn-color;
    border: none;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 115px;
    cursor: pointer;
    outline: none;
    border-radius: 4px;

    .img {
        margin-left: 8px;
    }
}

$blue: #4c7deb;
$font: "vazir", system-ui, Arial, sans-serif;
button {
    font-family: $font;
}

.sidebar {
    a {
        display: block;
        width: 100%;
    }

    height: 100%;
}

.container .sidebar .img .popup-sidebar {
    z-index: 999;
}

.rbc-toolbar button {
    font-size: 16px;
    font-family: $font;
    margin: 5px !important;
    border: 0 !important;
    box-shadow: 0 0 1px black;
    border-radius: 5px !important;
    outline: 0;
}

.rbc-row-segment .rbc-event-content {
    font-size: 13px;
}

.container .sidebar .img {
    text-align: center;
    color: white;

    i {
        font-size: 21px;
    }
}

.container .sidebar .img .popup-sidebar {
    white-space: nowrap;
}

.container .sidebar .img:hover .popup-sidebar {
    max-width: 600px;
    padding: 14px;
}

.rbc-day-bg,
.rbc-header {
    border-left: 1px solid #ddd;
}

.rbc-row-bg .rbc-day-bg:last-child,
.rbc-row .rbc-header:last-child {
    border-left: 0;
}

.blue {
    color: #4c7deb;
}

.container .main-section .main-header .main-header-img {
    display: flex;
    align-items: center;
    align-items: center;
    font-size: 13px;
    text-align: left;
    font-weight: normal;
}

.container .main-section .main-header .main-header-img img {
    margin: 0 8px;
    max-height: 50px;
    border: 1px solid $blue;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 5px;
}

.rbc-calendar {
    min-height: 800px;
}

.rbc-row-segment .rbc-event-content a {
    display: block;
    color: white;
    text-align: right;
}

.container .sidebar .circle {
    margin: 15px;
    width: 40px;
    height: 38px;
    padding-top: 3px;
    padding-right: 1px;
    color: #38405b;
    background: transparent;

    border: 0px !important;
}

.rbc-event-content {
    flex: 0 0 100%;
    direction: rtl;
    text-align: right;
    padding-right: 5px;
    padding-top: 10px;

    a {
        color: white;
    }
}

.rbc-day-slot .rbc-event {
    flex-wrap: wrap;
    padding: 10px;
}

.f-14 {
    font-size: 14px;
}

.f-16 {
    font-size: 16px;
}

.f-18 {
    font-size: 18px;
}

.f-20 {
    font-size: 20px;
}

.f-22 {
    font-size: 22px;
}

.project-logo {
    max-width: 30px;
    max-height: 30px;
}

.container .main-section .box-section .box .box-main {
    justify-content: space-around;
    text-align: center;

    .left {
        margin: 0 !important;
    }
}

.main-header-img {
    .main-header-img-popup {
        position: absolute;
        left: 0;
        top: calc(100% + 6px);
        right: 0;
        border-radius: 0 0 8px 8px;
        background-color: white;
        border: 1px solid #c0c8ff7c;
        border-top: 0;
        z-index: 1;
        box-shadow: 0 1px 1px #bbc5ff;

        span {
            padding: 10px;
            color: $blue;
            display: block;
            text-align: center;
            cursor: pointer;
        }
    }
}

.rbc-row-segment .rbc-event-content {
    padding: 3px !important;
}

.rbc-day-slot .rbc-event-label {
    display: none;
}

.login-section .login__input {
    width: 85%;
}

.tranparent-button {
    display: inline-flex;
    align-items: center;
    padding: 5px 10px 2px;
    border-radius: 5px;
    border: 1px solid $blue;
    background-color: transparent;
    color: $blue;
    font-size: 13px;
    cursor: pointer;
}

.project-logo.big-image {
    max-width: 50px;
    max-height: 50px;
}

.jc-between {
    justify-content: space-between;
}

.f-12 {
    font-size: 12px;
}

.f-14 {
    font-size: 14px;
}

.container .main-section .box-section .box.full-border {
    border: 0px solid #54a6f3;
    box-shadow: 0 0 3px #54a6f3;
}

.select-options {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% + 5px);
    border-radius: 5px;
    background-color: white;
    color: $blue;
    font-size: 14px;
    text-align: center;
    box-shadow: 0 0 1px rgba(84, 166, 243, 0.3);
    z-index: 9;

    .select-option {
        padding: 5px 10px;
    }
}

.input {
    width: 100%;
    border: 1px solid $blue;
    padding: 5px 10px;
    border-radius: 5px;
}

.grow-1 {
    flex-grow: 1;
}

.container .main-section .box-section {
    padding-bottom: 26px;
}

.container .main-section .box-section .box.main-box-header {
    min-height: calc(100vh - 175px);
    overflow: hidden;
    margin: 0;
}

.container .main-section .box-section .box.main-box {
    min-height: calc(100vh - 120px);
    overflow: hidden;
    margin: 0;
}

@keyframes loading {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;

    > div {
        height: 75px;
        width: 75px;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        color: $joqqd-purple;
        text-align: center;

        &.loading-anim {
            border-right: 2px solid $joqqd-purple;
            animation: loading 0.5s linear infinite;
        }
    }
}

.min-h-1px {
    min-height: 1px;
}

.project-logo.big-image {
    border-radius: 50%;
    border: 1px solid $blue;
    overflow: hidden;
}

.container .sidebar {
    min-height: 100vh;
}

.transparent-button-header {
    border: 1px solid #b6d1f8;
    padding: 8px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $blue;
}

.filepond--credits {
    display: none;
}

.filepond--root {
    margin: 0;
}

.filepond--panel-root {
    background-color: transparent;
    border: 1px solid $blue;
}

.filepond--drop-label {
    color: $blue;
}

.container .main-section .box-section .box {
    &.new-project {
        min-height: 185px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .new-project__text {
            align-items: center;
            display: flex;
            color: $blue;
        }

        .plus-icon {
            padding-left: 10px;
            font-size: 24px;
            color: $blue;
        }
    }
}

.auto-close {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: default;
}

.nowrap {
    white-space: nowrap;
}

.new-event {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
    background-color: rgba(black, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;

    .box {
        position: relative;
    }
}

.close-box {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

.popup-box {
    width: 400px;
    max-width: 100%;
}

.box {
    margin-bottom: 15px;
    border-top: 3px solid #54a6f3;
    border-radius: 6px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 8px 16px 16px;
}

.rdp__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 10px;

    & > button {
        margin: 0 !important;
        order: 2;
    }

    & > button:last-child {
        justify-self: center;
        flex: 0 0 100%;
        margin-bottom: 10px !important;
        order: 1;
    }
}

.eVqaMu svg,
.eVqaMu:hover svg {
    fill: #fff;
}

.dp__input {
    width: 100%;
    text-align: center;
    font-family: $font;
}

.jc-around {
    justify-content: space-around;
}

.flex-wrap {
    flex-wrap: wrap;
}

textarea.input {
    /* will prevent resizing horizontally */
    resize: vertical;
}

.iBtmLc button {
    background-image: linear-gradient(to right, #4c8deb, #4c60eb);
    background: $btn-color;
    border: none;
    color: #fff !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 115px;
    cursor: pointer;
    outline: none;
    border-radius: 4px;
}

.iBtmLc .rdp__button--cancel:hover,
.iBtmLc .rdp__button--cancel:focus {
    color: #fff;
    background-color: #d6d6d6;
}

// Mobile Responsive

.container .main-section .main-header.mobile-main-header {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    overflow: hidden;
    background: $blue;
    z-index: 9999999999;

    .circle {
        height: 70px;

        img {
            max-width: 100%;
            max-height: 100%;
            width: AUTO;
            padding: 10px;
        }
    }

    .transparent-button-header {
        border: 0px solid #b6d1f8;
    }
}

.f-25 {
    font-size: 25px;
}

.lg-12 {
    max-width: 100vw;
    overflow-x: hidden;
    overflow-y: visible;
}

.container .main-section .box-section {
    padding: 0px;
}

@keyframes menuAnim {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

.mobileNavMenu {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9999999999;
    background: $blue;
    color: white;
    padding: 15px;
    animation: menuAnim 0.5s linear 1;

    .close {
        position: absolute;
        left: 15px;
        top: 15px;
    }

    .sidebar {
        width: 100%;
        display: block;
        min-height: auto;
        background: transparent !important;
        padding-top: 30px;

        .circle {
            display: none;
        }
    }

    .sidebar .img {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .popup-sidebar {
            order: 2;
        }
    }

    .sidebar .img .popup-sidebar {
        position: static;
        right: auto;
        top: auto;
        padding: 10px;
        background: transparent;
        transform: none;
        color: #fff;
        max-width: 100%;
        transition: 0.15s all linear;
        overflow: hidden;
        border-radius: 0;
    }
}

.mobileNav {
    overflow-y: scroll;
}

.box-section.p-x-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.min-height-page {
    min-height: calc(100vh - 150px);
}

.min-height-page-mobile {
    min-height: calc(100vh - 95px);
}

.floating-button {
    position: fixed;
    left: 15px;
    bottom: 15px;
    background: #4c7deb;
    color: white;
    padding: 10px 15px;
    font-size: 12px;
    z-index: 9999999;
    border-radius: 20px;
}

@media only screen and (max-width: 600px) {
    .rbc-header {
        font-size: 10px;
    }
    .rbc-toolbar button {
        font-size: 11px;
    }
    .rbc-btn-group {
        flex: 0 0 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        order: 2;
    }
    .rbc-date-cell {
        font-size: 11px;
    }
    .rbc-row-segment .rbc-event-content {
        font-size: 10px;
        white-space: normal;
        padding: 1px !important;
    }
    .rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
        font-size: 12px;
    }
}

@media only screen and (max-width: 580px) {
    .rbc-header {
        font-size: 8px;
    }
}

.flex-wrap {
    flex-wrap: wrap;
}

.rbc-row-segment {
    padding: 0 10px;
}

.rbc-event {
    padding: 0 !important;

    .rbc-event-content {
        padding: 0 !important;
    }

    .event-cal {
        padding: 5px;
        border-radius: 8px;
    }
}

.social-network-icon {
    max-width: 20px;
    max-height: 20px;
}

.text-justify {
    text-align: justify;
}

.ltr {
    direction: ltr;
}

.home-boxes {
    max-height: calc(100vh - 165px);
    overflow: hidden;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.notification-center {
    position: absolute;
    color: $blue;
    top: -5px;
    left: -5px;
    font-size: 9px;
}

.f-28 {
    font-size: 28px;
}

.main-header-notification-popup {
    position: absolute;
    left: 0;
    top: calc(100% + 17px);
    background: white;
    max-height: 300px;
    overflow: hidden;
    width: 300px;
    z-index: 999;
    border-radius: 5px;
    border: 1px solid $blue;
    text-align: center;

    .notif-scroll {
        max-height: 300px;
        overflow: hidden;
        padding: 5px 10px;
    }
}

.register-message {
    position: absolute;
    top: calc(100% - 20px);
    right: 0;
    max-width: 260px;
}

.task-management {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid $blue;

    .icon {
        font-size: 18px;
    }
}

.flex-column {
    flex-direction: column;
}

.task-item {
    max-width: 30px;
    margin: auto;
    margin-right: 10px;
    max-height: 30px;

    &.vertical {
        margin: 10px auto 0;
    }

    > img {
        max-width: 25px;
        max-height: 25px;
    }
}

#toast-container {
    z-index: 999999999999;
}

.task-popup {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 300px;
    border-radius: 8px;
    box-shadow: 0 0 10px black;
    background-color: white;
    z-index: 9999;
    padding: 15px;
}

.bg-light-blue {
    background-color: rgba($blue, 0.6);
}

.bg-light-black {
    background-color: rgba(black, 0.6);
}

.z-index-1 {
    z-index: 1;
}

.task-item-text {
    border: 1px solid $blue;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $blue;
    font-size: 10px;
    overflow: hidden;
}

.full-width-link {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
}

.notification-item {
    border-bottom: 1px solid gray;

    .text {
        font-size: 12px;
    }
}

.notification-item:nth-last-child(1) {
    border: 0 !important;
}

.activity-item {
    align-items: center;
    border-radius: 5px 0 0 5px;
    background-color: $blue;
    color: white;

    .img {
        width: 15px;
        height: 15px;
        border: 1px solid $blue;
        border-radius: 50%;
        font-size: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        transform: scale(2) translateX(2px);
        background-color: white;
        color: $blue;
    }

    .activity-text {
        font-size: 12px;
        padding: 5px;
        margin-right: 10px;
    }
}

.grow-1 {
    flex-grow: 1;
}

.jc-around {
    justify-content: space-around;
}

.activity-detail {
    font-size: 12px;
}

.activity-loading {
    position: relative;
    min-height: 200px;
}

.filepond--file-poster-overlay {
    min-width: 100%;
}

.new-design {
    textarea {
        resize: vertical;
    }

    background: white;

    .header {
        background-image: linear-gradient(-45deg, #c3a8cd, #7c4192);
        box-shadow: 0px 2px 15px 4px #c8c8c8;
    }

    .header-btns {
        .icon-parent {
            background-color: transparent;
            border-radius: 50px;
            border: 1px solid white;
            color: white;
            padding: 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            margin-left: 8px;
            font-size: 2.4rem;

            &:hover {
                color: white;
            }
        }
    }

    .header-logo {
        img,
        picture {
            max-width: 105px;
            max-height: 80px;
        }
    }

    .dropdown-menu {
        position: relative;
        cursor: pointer;

        .dropdown-list {
            position: absolute;
            left: 7px;
            width: 190px;
            padding: 8px;
            border-radius: 5px;
            background: $default-color;
            color: white;
            z-index: 99;
            top: calc(100% + 5px);

            a,
            span {
                color: white;
                display: block;
                padding: 10px 8px 4px;
            }
        }
    }

    .search-parent {
        position: relative;

        input {
            border: none;
            border-radius: 8px;
            width: 400px;
            padding: 8px 32px 8px 20px;
            outline: none;
            font-size: 13px;
            max-width: 50vw;
            min-width: 40vw;
            color: #428675;
        }

        i {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 9px;
            font-size: 22px;
            color: $green;
        }
    }

    .back-smoke {
        background-color: #f7f8fc;
    }

    .home-ideas {
        padding: 10px;
        box-shadow: 0 5px 10px rgba(151, 146, 146, 0.1);
        border-radius: $d-radius;
        background-color: #ffff00;
        height: 100%;

        h2 {
            font-size: 1.6rem;
            margin-bottom: 20px;
        }

        .ideas-repeater {
            .each-idea {
                background-color: white;
                border-radius: $d-radius;
                color: black;
                padding: 5px 15px;
                margin: 10px 0;

                span {
                    font-size: 1.4rem;
                }
            }
        }
    }

    .projects,
    .ideas,
    .post-preview {
        padding: 10px;
        box-shadow: 0 5px 10px rgba(151, 146, 146, 0.1);
        border-radius: $d-radius;
        background-color: white;
        height: 100%;

        .post-attr {
            margin-top: 30px;

            .tags-parent {
                margin: 10px 0;
                display: flex;
                flex-direction: row;
            }
        }

        .post-desc {
            padding: 0 10px;

            h4 {
                font-size: 1.6rem;
                font-weight: bold;
            }
        }

        .post-title {
            display: flex;
            flex-direction: row;

            img {
                max-width: 80px;
                max-height: 32px;
            }
        }

        h2 {
            font-size: 1.6rem;
            margin-bottom: 20px;
        }

        .projects-repeater {
            display: flex;
            flex-direction: column;
            margin: 10px;

            .each-post-summary {
                padding: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 5px;
                position: relative;
                border-radius: $d-radius;
                color: white;

                &.published {
                    background-color: #38e67e;
                }

                &.waiting {
                    background-color: #e5da3d;
                }

                &.draft {
                    background-color: #b88afe;
                }

                &.queue {
                    background-color: #4cbfe4;
                }

                &.rejected {
                    background-color: #ff8787;
                }
            }

            .each-project {
                padding: 5px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 5px;
                position: relative;

                img {
                    max-width: 80px;
                    max-height: 32px;
                }

                &:after {
                    content: "";
                    position: absolute;
                    border: 1px solid #f1f1f1;
                    background-color: #f1f1f1;
                    top: 100%;
                    left: 0;
                    right: 0;
                }
            }
        }
    }

    .navigation {
        position: absolute;
        top: -65px;
        right: 0;
        display: flex;

        a,
        span {
            padding: 20px;

            &.active {
                border-radius: $d-radius $d-radius 0 0;
                background-color: #f7f8fc;
            }
        }
    }

    .dash_con {
        display: none;
    }

    .js-click {
        cursor: pointer;
    }

    .dash_con.active {
        display: block;
    }

    .f-2 {
        font-size: 3.2rem !important;
    }

    .add-new {
        display: flex;
        flex-direction: row;

        a {
            display: flex;
            background-color: $btn-color;
            padding: 10px;
            color: white !important;
            border-radius: $d-radius;
            justify-content: center;
            align-items: center;
            text-decoration: none !important;

            i {
                font-size: 1.9rem;
            }
        }
    }

    .pointer {
        cursor: pointer;
    }

    .post-sidebar {
        & > div {
            background-color: #ecedee;
            border-radius: $d-radius;
            padding: 15px;

            &:not(:first-child) {
                margin-top: 20px;
            }
        }
    }

    .people-in {
        .heading {
            display: flex;
            justify-content: space-between;

            .plus {
                font-size: 1.92rem;
                background-color: $btn-color;
                width: 25px;
                border-radius: 5px;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
            }
        }

        .people {
            display: flex;

            span {
                overflow: hidden;
                border-radius: 50px;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid black;

                img {
                    width: 100%;
                }

                &:not(:last-child) {
                    margin-left: 5px;
                }
            }
        }
    }

    .notif-setting {
        .notif {
            .notif-service {
                width: 100%;
                justify-content: space-between;
                display: flex;
            }

            .notif-date {
                display: flex;
                justify-content: space-between;

                input {
                    outline: none;
                    border-radius: 5px;
                    border: 1px solid #999;
                }

                .day {
                    span {
                        input {
                            max-width: 100px;
                        }
                    }
                }

                .time {
                    span {
                        input {
                            max-width: 100px;
                        }
                    }
                }

                .day,
                .time {
                    span {
                        label {
                            font-size: 1.5rem;
                        }
                    }
                }
            }

            .notif-btn {
                a {
                    background-color: $btn-color;
                    color: white;
                    border-radius: $d-radius;
                    display: inline-block;
                    align-items: center;
                    justify-content: center;
                    padding: 8px 20px;
                }
            }

            .notif-status {
                display: flex;
                justify-content: space-between;
                border: 1px solid #666;
                border-radius: 8px;
                align-items: center;
                padding: 10px;

                p,
                span {
                    color: #666;
                    margin-bottom: 0 !important;
                }
            }
        }
    }

    .cutsom-checkbox-1 {
        display: block;
        position: relative;
        padding-right: 25px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:checked {
                ~ .checkmark {
                    background-color: #2196f3;

                    &:after {
                        display: block;
                    }
                }
            }
        }

        &:hover {
            input {
                ~ .checkmark {
                    background-color: #ccc;
                }
            }
        }

        .checkmark {
            &:after {
                left: 8px;
                top: 5px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }

    .checkmark {
        position: absolute;
        top: 0;
        right: 0;
        height: 20px;
        width: 20px;
        background-color: white;
        border-radius: 5px;

        &:after {
            content: "";
            position: absolute;
            display: none;
        }
    }

    .publish-segment {
        p {
            display: flex;
            justify-content: space-between;

            &:last-child {
                margin-bottom: 0 !important;
            }
        }
    }

    .confirmation {
        .people-to-confirm {
            display: inline-block;
        }
    }

    .confirmation,
    .publish-segment,
    .notif-setting,
    .people-in {
        .heading {
            font-weight: bold;
            font-size: 1.92rem;
            margin-bottom: 20px;
        }
    }

    .post-overview {
        & > div:not(:last-child) {
            background-color: #fff;
            border-radius: $d-radius;
            padding: 25px 15px;

            &:not(:first-child) {
                margin-top: 40px;
            }
        }

        .post-desc {
            .post-and-proj,
            .post-details {
                p {
                    display: flex;
                    flex-direction: column;
                }

                display: flex;
                justify-content: space-between;
            }
        }

        .post-text {
            .heading {
                display: flex;
                justify-content: space-between;

                & > div {
                    display: flex;
                    align-items: center;
                }

                .check-notif {
                    span {
                        color: #999;
                        width: 30px;
                        height: 30px;
                        display: flex;
                        border-radius: 50px;
                        border: 1px solid #999;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            .post-text-body {
                margin-top: 30px;

                .submit-btn {
                    padding: 10px 50px;
                    background-color: $btn-color;
                    color: white !important;
                    text-align: center;
                    border-radius: $d-radius;
                }

                textarea {
                    border: none;
                    background-color: #fbfbfb;
                    border: 1px solid #e6e5e5;
                    outline: none;
                    max-height: 250px;
                }

                .responsible-people {
                    .images {
                        display: flex;

                        span {
                            max-width: 45px;
                            border-radius: 50px;
                            border: 1px solid #999;
                            display: flex;
                            overflow: hidden;
                            align-items: center;
                            justify-content: center;

                            &:not(:last-child) {
                                margin-left: 10px;
                            }
                        }

                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .comments {
        .comments-parent {
            background-color: #fbfbfb;
            overflow-y: auto;
            border-radius: $d-radius;

            .comments-child {
                position: relative;
                border-radius: $d-radius;
                background-color: white;
                padding: 10px;
                padding-bottom: 15px;
                display: flex;
                align-items: flex-start;
                margin: 10px 0px;

                .comment-date {
                    font-size: 12px;
                    position: absolute;
                    left: 5px;
                    bottom: 5px;
                }

                .comment-content {
                    margin-bottom: 0;
                    width: calc(100% - 60px);
                }

                .image {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    border: 1px solid #999;
                    border-radius: 50px;
                    overflow: hidden;
                    margin-top: 5px;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

    .f-15 {
        font-size: 2.4rem !important;
    }

    .text-bold {
        font-weight: bold !important;
    }

    .post-media,
    .post-tags {
        .heading {
            display: flex;
            justify-content: space-between;

            & > div {
                display: flex;
                align-items: center;
            }

            .check-notif {
                span {
                    color: #999;
                    width: 30px;
                    height: 30px;
                    display: flex;
                    border-radius: 50px;
                    border: 1px solid #999;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    .post-add {
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            color: white;
            background-color: $btn-color;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            font-size: 2.4rem;
            text-decoration: none;

            &:hover {
                color: white;
                text-decoration: none;
            }
        }
    }

    .submit-archieve {
        display: flex;
        margin-top: 40px;

        .submit-btn {
            color: white !important;
            background-color: $btn-color;
            border-radius: $d-radius;
            padding: 10px 30px;
            margin-left: 10px;
        }
    }

    .archieve-btn {
        color: white;
        background-color: $d-color;
        border-radius: $d-radius;
        padding: 10px 30px;
    }

    .align-items-center {
        align-items: center;
    }

    .justify-content-between {
        justify-content: space-between;
    }

    .position-relative {
        position: relative;
    }

    .container {
        display: block;
    }

    .projects .projects-repeater .each-project img {
        max-width: 50px;
        max-height: 20px;
    }

    .new-project-popup {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        width: 400px;
        background: white;
        padding: 15px;
        border-radius: 7px;
    }

    .input {
        padding: 5px 10px;
        border-radius: 3px;
        border: 0;
        border-bottom: 2px solid #bcbcbc;
        background: #efefef;
    }

    .btn {
        display: inline-flex;
        background-color: $btn-color;
        padding: 8px 15px;
        color: white !important;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
        text-decoration: none !important;

        &.btn-green {
            background-color: $green;
            color: white !important;
        }

        &.btn-red {
            background-color: $red;
            color: white !important;
        }
    }

    .cover {
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0;

        &.cover-bg {
            background-color: rgba(0, 0, 0, 0.4);
        }
    }

    .rbc-toolbar .rbc-btn-group:last-child {
        display: none;
    }

    .rbc-month-view {
        border: 0;
    }

    .rbc-day-bg,
    .rbc-header {
        border: 0;
    }

    .rbc-month-row + .rbc-month-row {
        border: 0;
    }

    .rbc-day-bg {
        background: white;
        margin: 5px;
    }

    .rbc-date-cell {
        flex: 1 1;
        min-width: 0;
        padding: 10px 15px;
        text-align: right;
    }

    .rbc-month-header {
        border: 0;
        padding: 10px 0;
        margin-bottom: 6px;
        background: #6176d4;
        color: white;
        border-radius: 5px;
    }

    .rbc-toolbar {
        direction: ltr;
    }

    .pos-rel-im {
        position: relative !important;
    }

    .pos-st-im {
        position: static !important;
    }

    .ideas,
    .post-preview {
        h2 {
            font-size: 2.2rem;
            margin-bottom: 20px;
        }

        margin-bottom: 15px;
    }

    .default-ddr {
        background-color: white;
        border-radius: 5px;
    }

    .default-box {
        padding: 10px;
        border-radius: 5px;
        background-color: white;
    }

    .mini-logo {
        max-height: 30px;
    }

    .checkmark {
        border: 1px solid black;
    }

    .mini-image {
        max-height: 50px;
    }

    .new-button {
        display: flex;
        background-color: $btn-color;
        padding: 10px;
        color: white !important;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
        text-decoration: none !important;
    }

    .button-green {
        display: flex;
        background-color: #65c747;
        padding: 10px;
        color: white !important;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
        text-decoration: none !important;
    }

    .self-end {
        align-self: flex-end;
    }

    .new-idea-menu {
        padding: 20px 35px 15px;
        color: white;
        position: relative;
        background-color: $btn-color;
        top: -5px;
        border-radius: 5px 0 5px 5px;
    }

    .login-section-new {
        min-height: 100vh;
        background: #eee;

        .screen {
            width: 700px;
            max-width: 100vw;
            border-radius: 15px;
            background: white;
            position: absolute;
            left: 50%;
            top: 50%;
            overflow: hidden;
            transform: translate(-50%, -50%);

            .login-bg-img {
                background: $btn-color;
                padding: 50px;
            }
        }

        .login-logo {
            max-width: 100px;
            margin: auto;
            padding-top: 35px;
        }
    }

    .textarea {
        border-radius: 8px;
        max-width: 100%;
        width: 100%;
        min-height: 150px;
        padding: 15px;
    }

    .cutsom-checkbox-1 .checkmark:after {
        left: 6px;
        top: 2px;
    }

    .plus-btn {
        font-size: 17px;
        background-color: $btn-color;
        width: 22px;
        border-radius: 5px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
    }

    .new-design .rbc-day-bg:before {
        content: " ";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 99;
    }

    .post-item-image {
        max-width: 35px;
    }

    .filepond--drop-label {
        color: #efefef;
    }

    .filepond--panel-root {
        border-color: #efefef !important;
    }

    .post-media,
    .black-filepond {
        .filepond--drop-label {
            color: black;
        }

        .filepond--panel-root {
            border-color: black !important;
        }
    }

    .idea-box .mini-image {
        max-width: 45px;
        margin-left: 6px;
        margin-top: 12px;
    }

    .rbc-date-cell {
        position: relative;
    }

    .rbc-date-cell:before {
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 99;
    }

    .task-item-text {
        width: 35px;
        height: 35px;
    }

    .current-users-container {
        margin-right: -10px;
        margin-left: -10px;
        padding-top: 5px;
    }

    .box {
        border: 0;
    }

    .dp__input {
        background-color: transparent;
    }

    .top-menu-sub {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        top: 100%;
        border-radius: 0 0 5px 5px;
        background-color: #f7f8fc;
    }

    .post-confirm-container {
        background-color: transparent !important;
        padding: 0 !important;

        .confirmation {
            background: #ecedee;
            color: black;
            padding: 8px 15px;
            font-size: 13px;
            border-radius: 5px;
        }

        .confirmation:not(last-child) {
            margin-bottom: 15px;
        }
    }

    .ps .ps__rail-x:hover,
    .ps .ps__rail-y:hover,
    .ps .ps__rail-x:focus,
    .ps .ps__rail-y:focus,
    .ps .ps__rail-x.ps--clicking,
    .ps .ps__rail-y.ps--clicking {
        background-color: transparent;
    }

    .min-h-200px {
        min-height: 200px;
    }
}

.new-design .post-overview > div:not(:last-child):not(:first-child) {
    margin-top: 25px;
}

.calendar-mini-image {
    max-width: 20px !important;
}

@media (min-width: 600px) {
    .horizontal-filepond .filepond--item {
        width: calc(50% - 0.5em);
    }
}

@media (max-width: 600px) {
    .horizontal-filepond .filepond--item {
        width: calc(33.33% - 0.5em);
    }
}

.btn,
.button,
.new-button,
a {
    cursor: pointer;
}

@for $i from 1 through 12 {
    .z-index-#{$i} {
        z-index: $i;
    }
}

.comment-textarea {
    border: none;
    background-color: #fbfbfb;
    border: 1px solid #e6e5e5;
    outline: none;
    max-height: 250px;
}

.ps__rail-y {
    z-index: 3;
}

.preview-post-calendar {
    margin: 10px;
    padding: 15px;
    padding-bottom: 0px;
    text-align: center;

    > div {
        margin-bottom: 10px;
    }

    border: 1px solid #e6e5e5;
    border-radius: 3px;
}
.bring-to-front {
    position: relative;
    z-index: 9;
}

.post-preview-container {
    .post-preview {
        margin-bottom: 15px;
    }
}

.next-post-container {
    .post-preview {
        box-shadow: none;
        justify-content: space-between;
    }
}

.m-0-im {
    margin: 0 !important;
}
.new-design .current-users-container {
    display: inline-flex;
}

.new-design .post-overview .post-text .post-text-body .post-textarea {
    min-height: 340px;
    max-height: 550px;
}

.floating-new-post {
    position: fixed;
    height: 60px;
    left: 15px;
    bottom: 15px;
    background: #7c4192;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    font-size: 24px;
    border-radius: 50px;

    i {
        display: inline-block;
        padding: 10px 19px;
        transition: 0.5s all linear;
    }

    .content {
        font-size: 18px;
        max-width: 0;
        overflow: hidden;
        transition: all 0.5s ease-in;
        opacity: 0;
        > span {
            width: 105px;
        }
    }

    &:hover {
        // i {
        //     padding: 10px 19px 10px 0px;
        // }

        .content {
            max-width: 88px;
            transform: translateX(19px);
            opacity: 1;
        }
    }
}
.h-auto-im {
    height: auto !important;
}

.pre-line {
    white-space: pre-line;
}
.w-nowrap {
    white-space: nowrap;
}
.w-normal {
    white-space: normal;
}
