@import url("https://fonts.googleapis.com/css?family=Raleway:400,700");
.login-section {
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    background: linear-gradient(90deg, #fff, #4c8deb);

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
    }

    .screen {
        background: linear-gradient(90deg, #4c8deb, #4c60eb);
        position: relative;
        height: 600px;
        width: 360px;
        box-shadow: 0px 0px 24px #4c8deb;
        border-radius: 15px;
        overflow: hidden;
    }

    .screen__content {
        z-index: 1;
        position: relative;
        height: 100%;
    }

    .screen__background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        -webkit-clip-path: inset(0 0 0 0);
        clip-path: inset(0 0 0 0);
    }

    .screen__background__shape {
        transform: rotate(45deg);
        position: absolute;
    }

    .screen__background__shape1 {
        height: 520px;
        width: 520px;
        background: #fff;
        top: -50px;
        left: 120px;
        border-radius: 30px;
    }

    .screen__background__shape2 {
        height: 220px;
        width: 220px;
        background: #4c8deb;
        top: -172px;
        left: 0;
        border-radius: 32px;
    }

    .screen__background__shape3 {
        height: 540px;
        width: 190px;
        background: linear-gradient(270deg, #4c8deb, #4c60eb);
        top: -24px;
        left: 0;
        border-radius: 32px;
    }

    .screen__background__shape4 {
        height: 400px;
        width: 200px;
        background: #4c8deb;
        top: 420px;
        left: 50px;
        border-radius: 60px;
    }

    .login {
        width: 320px;
        padding: 30px;
        padding-top: 156px;
    }

    .login__field {
        padding: 20px 0px;
        position: relative;
    }

    .login__icon {
        position: absolute;
        top: 30px;
        color: #4c8deb;
    }

    .login__input {
        border: none;
        border-bottom: 2px solid #d1d1d4;
        background: none;
        padding: 10px;
        padding-right: 24px;
        font-weight: 700;
        width: 75%;
        transition: 0.2s;
    }

    .login__input:active,
    .login__input:focus,
    .login__input:hover {
        outline: none;
        border-bottom-color: #4c60eb;
    }

    .login__submit {
        background: #fff;
        font-size: 14px;
        margin-top: 30px;
        padding: 16px 20px;
        border-radius: 26px;
        border: 1px solid #d4d3e8;
        text-transform: uppercase;
        font-weight: 700;
        display: flex;
        align-items: center;
        width: 100%;
        color: #4c60eb;
        box-shadow: 0px 2px 2px #4c60eb;
        cursor: pointer;
        transition: 0.2s;
    }

    .login__submit:active,
    .login__submit:focus,
    .login__submit:hover {
        border-color: #4c60eb;
        outline: none;
    }

    .button__icon {
        font-size: 24px;
        margin-right: auto;
        color: #4c60eb;
    }

    .social-login {
        position: absolute;
        height: 140px;
        width: 160px;
        text-align: center;
        bottom: 0px;
        left: 0px;
        color: #fff;
    }

    .social-icons {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .social-login__icon {
        padding: 20px 10px;
        color: #fff;
        text-decoration: none;
        text-shadow: 0px 0px 8px #4c60eb;
    }

    .social-login__icon:hover {
        transform: scale(1.5);
    }
}
.login-section .login.register {
    padding-top: 30px;
}
