.purple .rmdp-day.rmdp-today span {
    background-color: #5f5460 !important;
}
.rmdp-analog-clock .dial-lines {
    transform-origin: 50% 60px !important;
}
.rmdp-analog-clock .rmdp-minute {
    top: 9px !important;
}
.rmdp-analog-clock .rmdp-hour {
    top: 30px !important;
}
.rmdp-analog-clock .rmdp-h9 {
    top: 51px !important;
}
.rmdp-analog-clock .rmdp-h3 {
    top: 51px !important;
}
.rmdp-container {
    display: block !important;
    width: 100% !important;
}
